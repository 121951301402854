<template lang="pug">
   .con(v-if="!$store.state.pcshow")
      img(src="../assets/img/img33.png")
      .hcooperation
        h3.conh3 COOPERATIVE APPLICATION
        h4.conh4 合作申请
      .from
        .li.clearfix
          lable 姓名
            span *
          input(type="text", v-model="name", placeholder="请输入您的姓名")
        .li.clearfix
          lable 电话
            span *
          input(type="text", v-model="phone", placeholder="请输入您的电话")
        .li.clearfix
          lable 邮箱
            span *
          input(
            type="text",
            v-model="email",
            placeholder-class="namePlace",
            placeholder="请输入您的邮箱"
          )
        .li.clearfix
          lable 留言
            span *
          textarea(v-model="todo", placeholder="我们能为您做什么")
        button 提交
      .hcontact
          h3.conh3 CONTACT INFORMATION
          h4.conh4 联系方式
          img(src="../assets/img/img34.png")
          .group
            p(style="border:0 !important;") 联系地址：北京市朝阳区东三环南路98号高和
            p(style="border:0 !important;height:0.5rem;line-height: 0.2rem;")
              span(style="color:#fff") 联系地址：
              span 蓝峰大厦A座1110室
            p(style="border-top:0.01rem solid #eaeaea !important;") 工作时间：周一至周五09:00-17:30
            
   .ContactUs(v-else)
    img(src="../assets/img/corporateNews/img (2).jpg")
    .min
      h2 LEAVE US A MESSAGE
      h3 给我们留言
      .from
        .input
          span.lable  姓名：
          input(type="text" v-model="name" placeholder="请输入您的姓名")
          b *
        .input
          span.lable  电话：
          input(type="text" v-model="phone" placeholder="请输入您的电话")
          b *
        .input
          span.lable  邮箱：
          input(type="text" v-model="email" placeholder-class="namePlace"   placeholder="请输入您的邮箱")
          b *
        .input
          span.lable  留言：
          textarea(v-model="todo" placeholder="我们能为您做什么")
          b *
      button 提交
    .contact
      .mins
        .left
          h2 CONTACT INFORMATION
          h3 联系方式
          //- p 联系电话：000-0000
          //- p 邮 箱：XXX@.COM
          p 工作时间：周一至周五09:00-17:30
          p 联系地址：北京市朝阳区东三环南路98号高和蓝峰大厦A座1110室
        img(src="../assets/img/corporateNews/img (4).png")
</template>

<script>
export default{
  name: 'ContactUs',
  data() {
    return {
      name:'',
      phone:'',
      email:'',
      todo:'',
    }
  }
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .con
      width: 100%;
      background: #F5F5F5;
      >img
        display: block
        width:7.5rem;
        height: 3rem;
    .hcooperation
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          position: relative
          margin-top:-0.16rem
        
        .from
          background: #ffffff;
          padding-left:0.34rem;
          width:calc( 100% - 0.34rem );
          
          .li
            border-bottom:0.01rem solid #EAEAEA;

            lable
              width: 0.8rem;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 0.5rem;
              float: left;
              height:1rem;
              line-height:1rem;

              
              span
                color:#EE0A24;
              
            
            input::-webkit-input-placeholder,textarea::-webkit-input-placeholder
              color: #999999;
              font-weight: 500;
              font-family: PingFang SC;
            


            input
              width:5.15rem;
              height:1rem;
              line-height:1rem;
              border:none;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              font-size: 0.3rem;
              float:left
              margin-left:0.39rem;
            
            textarea
              margin-left:0.1rem
              width: 5.67rem;
              height: 1.94rem;
              background: #F5F5F5;
              float:left;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 0.5rem;
              margin-top: 0.24rem;
              padding-left:0.3rem;
              padding-top:0.24rem;
              resize:none;
            
          
          .li:nth-child(4)
            border-bottom:0;
          
          button
            width: 6.8rem;
            height: 0.88rem;
            line-height: 0.88rem;
            background: #0085D0;
            border-radius: 0.44rem;
            font-size: 0.3rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            border: 0;
            margin: 0.65rem 0 0.4rem 0
        .hcontact
          display block
          width calc( 100% - 0.36rem )
          padding-left 0.36rem
          padding-bottom:0.4rem;

          img
            display: block;
            width:6.82rem;
            height: 3.22rem;
          

          .group
            width:6.21rem;
            padding-left: 0.24rem;
            padding-right:0.35rem;
            background:#ffffff;
            border-bottom-left-radius:0.1rem;
            border-bottom-right-radius:0.1rem;

            p
              display block
              width: 6.21rem;
              height:1.02rem;
              line-height:1.02rem;
              font-size: 0.3rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              // border-top:0.01rem solid #EAEAEA;
            
            p:last-child
              border:0;
      .conh3
        padding-top: 0.4rem
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      
      .conh4
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        padding 0.15rem 0 0.24rem 0
      
  @media screen and (min-width: 960px)
    .ContactUs
      padding-top 90px
    .min
      width 1200px
      margin 119px auto 0
      h2
        font-size 30px
        font-weight bold
        color #333333
        height 24px
        line-height 24px
        text-align center
      h3
        font-size 30px
        color #333333
        line-height 39px
        font-weight 400
        text-align center
        margin-top 18px
        margin-bottom 96px
      .from
        display flex
        flex-wrap: wrap
        .input
          display flex
          align-items center
          margin-bottom 21px
          .lable
            font-size 15px
            font-weight 400
            color #333333
            width 50px
            margin-left 30px
          input
            width 287px
            height 36px
            border 1px solid #EAEAEA
            padding-left 7px
            line-height 36px
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #999999
            &:focus 
              outline:none;
              color #333333
              border 1px solid #EAEAEA
          b
            color #F82130
            margin-left 8px
          textarea
            width 1049px
            height 99px
            border 1px solid #EAEAEA
            padding 11px 7px
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #999999
            &:focus 
              color #333333
              outline:none;
              border 1px solid #EAEAEA
      button
        width 244px
        height 44px
        background #02B2B5
        text-align center
        line-height 44px
        margin-top 24px
        border 0
        margin-left: 78px
        color #fff
    .contact
      background #FAFAFA
      padding 85px
      margin-bottom 156px
      margin-top 130px
      .mins
        width 1200px
        margin 0 auto
        display flex
        justify-content space-between
        h2
          font-size: 30px;
          color: #333333;
          line-height: 39px;
          margin-top 90px
          margin-bottom 18px
        h3 
          height 29px
          line-height 29px
          font-size 30px
          margin-bottom 19px
        p 
          font-size 20px
          height 20px
          line-height 33px
          margin-top 31px
</style>